<template>
    <div class="gray-bg own" v-htmltit data-title="我的">
      <!-- <my-header :title="title"></my-header> -->
      <div class="my-main">
        <go-cart-btn></go-cart-btn>
        <div class="my-info">
          <div class="my-img">
            <van-image 
                round
                width="3.5rem"
                height="3.5rem"
                :src="img">
                <template v-slot:error>
                  <img style="width:3.5rem;height:3.5rem" :src="touxiang" />
                </template>
            </van-image>
          </div>
          <div class="my-name-box">
            <div class="my-name">{{name}}</div>
            <div class="my-store">
              <img :src="mendian" />
              <div>{{customerName}}</div>
            </div>
          </div>
          <div class="my-money" @click="handleClickPriceDetail">
            <div>余额</div>
            <div class="price">{{Number(balance).toFixed(2)}}</div>
          </div>
        </div>
        <div class="my-list">
          <div class="my-item" @click="handleClickPriceDetail">
            <div>余额明细</div>
            <div><van-icon name="arrow" /></div>
          </div>
          <div class="my-item">
            <a href="tel:010-87609907">
              <div>联系客服</div>
              <div><van-icon name="arrow" /></div>
            </a>
          </div>
          <div class="my-item" @click="handleClickMyAddress">
            <div>我的地址</div>
            <div><van-icon name="arrow" /></div>
          </div>
        </div>
      </div>
      <my-footer></my-footer>
    </div>
</template>
<script>
import "./index.less"
// import myHeader from "@/components/Header"
import goCartBtn from "@/view/Public/goCartBtn"
import myFooter from "@/components/Footer"
import {userInfo, GetUserBalance } from "@/apis/api"
import touxiang from "@/assets/image/touxiang.png"
import mendian from "@/assets/image/mendian.png"
export default {
    name:"my-own",
    components:{ goCartBtn, myFooter },
    data(){
      return{
        img:"",
        name:"",
        customerName:"",
        touxiang:touxiang,
        mendian:mendian,
        balance:0
      }
    },
    mounted(){
      this.getUserInfo();
      this.getBalance()
    },
    methods:{
      getUserInfo(){
        userInfo().then(res=>{
          if(res.code == 0){
            this.customerName = res.res.customerName
            this.img = res.res.avatars;
            this.name = res.res.name
          }
        })
      },
      getBalance(){
          let params = {
              customer_id: localStorage.getItem('customerId')
          }
          GetUserBalance(params).then(res=>{
              if(res.code== 0){
                  this.balance = res.res.balance
              }else{
                  this.$toast(res.msg)
              }
          })
      },
      handleClickMyAddress(){
        this.$router.push({ name:'address-list' })
      },
      handleClickPriceDetail(){
        this.$router.push({ name:'price-detail' })
      }
    }
}
</script>